<template>
    <SmartTable url="/settings/board-operation-type/index" base_path="/settings/board-operation-type"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Operazione Membro Consiglio", route: "/settings/board-operation-type/index" },
             { title: "Ricerca Tipi Operazione Membro Consiglio" }
         ]);
    },
 };
</script>
